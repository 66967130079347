<template>
  <section class="min-w1400">
    <!-- <div class="searchBar"> -->
      <!-- <date-selector :class="'searchbar-content'" /> -->
      <!-- <div :class="'searchbar-content'"> -->
        <!-- <search-filter :srchFiltersProp="srchFiltersProp" /> -->
      <!-- </div> -->
    <!-- </div> -->
    <div class="searchwrap">
      <div class="searchBar">
         <div class="box-ui-select searchbar-content" v-if="currentStatus === ''">
            <div class="title"><span>{{ $t('searchArea.joinDate') }}</span></div>
            <date-selector :class="'searchbar-content'" @setStartDate="setStartDate" @setEndDate="setEndDate" :startDefault="startDefault" :endDefault="endDefault" :configs="dateConfigs" />
         </div>
      </div>
      <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('table.head.category') }}</span></div>
           <div>
              <select class="mr-5" v-model="selectOption">
                 <option value="memId">{{ $t('searchArea.id') }}</option>
                 <option value="memNick">{{ $t('searchArea.nick') }}</option>
                 <option value="recommenderId">{{ $t('searchArea.highuser') }}</option>
              </select>
              <input type="text" class="mr-5" v-model="searchText" :placeholder="''" @keydown.enter="pageSeach" />
              <!--select class="mr-5">
                 <option disabled selected>상태</option>
                 <option value="">{{ $t('searchArea.total') }}</option>
                 <option value="">{{ $t('button.approval') }}</option>
                 <option value="">{{ $t('button.refu') }}</option>
              </select-->
              <button class="btn-search" type="button" @click="pageSeach">
                <i class="fas fa-search"></i>
              </button>
           </div>
         </div>
      </div>
      <memo></memo>
    </div>
    <div class="main-contents-wrapper">
      <div class="tab-wrap">
        <button type="button" class="tab-item" :class="{ active: currentStatus === '0' }" @click="changeListType" value="0">{{ $t('button.applyReq') }}</button>
        <button type="button" class="tab-item" :class="{ active: currentStatus === '2' }" @click="changeListType" value="2">{{ $t('button.waitList') }}</button>
        <button type="button" class="tab-item" :class="{ active: currentStatus === '1' }" @click="changeListType" value="1">{{ $t('button.appList') }}</button>
        <button type="button" class="tab-item" :class="{ active: currentStatus === '-1' }" @click="changeListType" value="-1">{{ $t('button.rejList') }}</button>
        <button type="button" class="tab-item" :class="{ active: currentStatus === '' }" @click="changeListType" value="">{{ $t('button.allList') }}</button>
      </div>
      <div class="table-wrapper">
        <table class="mainTable">
          <table-head :headInfo="headInfo" />
          <tbody v-if="applyList.length !== 0">
            <!-- <tr v-for="item in applyList" :key="item.memId" :class="{ hide: item.memStatus == 1 }"> -->
            <tr v-for="item in applyList" :key="item.memId">
              <td>
                <button type="button" class="fc-id btn-link" @click="detailOpen('user', item.memId)">{{ item.memId }}</button>
              </td>
              <td>
                <button type="button" class="fc-nick btn-link" @click="detailOpen('user', item.memId)">{{ item.memNick }}</button>
              </td>
              <td>
                 <span class="levelbox">
                    <span :class="item.partnerLevel">{{ computedPartnerLevel(item) }}</span>
                 </span>
              </td>
              <td>
                 <div class="topwrap" v-if="item.topUserList">
                    <select class="select">
                       <template v-for="pt in item.topUserList">
                          <option class="option">
                             <span class="topbox">[{{ computedPartnerLevel(pt) }}] </span>
                             <span :class="item.partnerLevel">{{pt.recommenderId}}</span>
                          </option>
                       </template>
                    </select>
                    <i class="icon">+</i>
                 </div>
              </td>
              <td>
                <span class="roboto">{{ item.memPhone }}</span>
              </td>
              <td>
                <div class="bankInfo-wrap">
                  <span>{{ item.memName }}</span>
                  <span>{{ item.bank }}</span>
                  <span>{{ item.bankacc }}</span>
                </div>
              </td>
              <!--td>
                <button type="button" v-if="item.recommenderId !== siteInfo.siteId" class="fc-id btn-link" @click="detailOpen('user',item.recommenderId)">{{ item.recommenderId }}</button>
                <button type="button" v-else class="fc-id btn-link">{{ item.recommenderId }}</button>
              </td-->
              <td>
                <span class="roboto">{{ item.redDt }}</span>
              </td>
              <td>
                <span>{{ item.siteDomain }}</span>
              </td>
              <td>
                <span class="roboto">{{ item.regIp }}</span>
              </td>
              <td style="width: 10%">
                <div class="status-change-btn-wrap">
                  <button class="btn-innerTable btn-status-change wait" type="button" value="2" :data-mem-id="item.memId" v-if="item.memStatus == '0'" @click="setMemStatus">{{ $t('common.wait') }}</button>
                  <button class="btn-innerTable btn-status-change approve" type="button" value="1" :data-mem-id="item.memId" v-if="item.memStatus == '2'" @click="setMemStatus">{{ $t('button.approval') }}</button>
                  <button class="btn-innerTable btn-status-change refuse" type="button" value="-1" :data-mem-id="item.memId" v-if="item.memStatus == '2'" @click="setMemStatus">{{ $t('button.refu') }}</button>
                  <button class="btn-innerTable btn-status-change approve" type="button" value="2" :data-mem-id="item.memId" v-if="item.memStatus == '-1'" @click="setMemStatus">{{ $t('common.restore') }}</button>
                </div>
              </td>
              <td v-if="reqData.memStatus === '-1' || reqData.memStatus === '' ">
                <span >{{ item.statusMemo }}</span>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="12">
                <span class="notice-tableDataEmpty">{{ $t('txt.noData') }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <pagination v-if="applyList.length !== 0" @goPage="setTableData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />
  </section>
</template>

<script>

import { getSiteData } from '@/libs/auth-helper'
import SearchFilter from '@/components/common/SearchFilter'
import DateSelector from '@/components/common/DateSelector'
import TableHead from '@/components/main/table/Head.vue'
import BtnVisibleToggle from '@/components/ui/BtnVisibleToggle'
import { memberStatusList, memStatusChange } from '@/api/member.js'
import { replaceDateT, numberWithCommas, getDateStr } from '@/libs/utils.js'
import Pagination from '@/components/common/Pagination'
import Memo from '@/components/common/memo'
import { PARTNER_LEVEL } from '@/libs/constants'

export default {
  name: 'signupList',
  components: {
    TableHead,
    DateSelector,
    SearchFilter,
    BtnVisibleToggle,
    Pagination,
    Memo
  },
  props: {
    status: {
      type: String,
      default: 'all'
    }
  },
  watch: {
    $route: {
      async handler () {
        // this.currentStatus = this.$route.params.status || this.$route.query.status
        // await this.setTableData(1);
      }
    }
  },
  data: function () {
    return {
      tableName: 'Basic',
      tableData: {
      },
      headInfo: {
        fstColumn: false,
        dataList: ['memId', 'memNick', 'level', 'belong', 'memPhone', 'refundAcc', 'memRegDt', 'regSiteDomain', 'memRegIp', 'statusChange']
      },
      dateConfigs: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i'
      },
      startDefault: '',
      endDefault: '',
      reqData: {
        page: 1,
        count_per_list: 30,
        memId: '',
        startDate: '',
        endDate: '',
        memStatus: this.currentStatus
      },
      pageInfo: {},
      applyList: [],
      siteInfo: {},
      currentStatus: '',
      selectOption: 'memId',
      searchText: ''
    }
  },
  methods: {
    computedPartnerLevel (item) {
      return PARTNER_LEVEL[item.partnerLevel]
    },
    setStartDate (date) {
      // console.log(date);
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(0, 0, 0)
      } else {
        _date = date[0]
        _date.setSeconds(59)
      }
      this.startDefault = _date
      this.reqData.startDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    setEndDate (date) {
      // console.log(date[0]);
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(23, 59, 59)
      } else {
        _date = date[0]
      }
      this.endDefault = _date
      this.reqData.endDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    pageSeach () {
      this.setTableData(1)
    },
    async setOption () {
      this.reqData.memId = ''
      this.reqData.memNick = ''
      this.reqData.recommenderId = ''
      this.reqData[this.selectOption] = this.searchText
    },
    async setTableData (pageNum, status) {
      if (pageNum) {
        this.reqData.page = pageNum
      }
      this.reqData.memStatus = this.currentStatus
      await this.setOption()
      const data = JSON.parse(JSON.stringify(this.reqData))
      if (status) {
        this.reqData.memStatus = status
      }
      const _status = this.reqData.memStatus
      const refuseMemoExt = this.headInfo.dataList.indexOf('refuseMemo') // 헤더에 거절사유 존재여부 확인
      const addPosition = this.headInfo.dataList.indexOf('statusChange') + 1

      if (_status === '-1' || _status === '') {
        if (refuseMemoExt === -1) {
          this.headInfo.dataList.splice(addPosition, 0, 'refuseMemo')
        }
      } else {
        if (refuseMemoExt !== -1) {
          this.headInfo.dataList.splice(addPosition, 1)
        }
      }
      console.log(data)

      let res
      if (data.memStatus === '') {
        res = await memberStatusList(data)
      } else {
        data.startDate = ''
        data.endDate = ''
        res = await memberStatusList(data)
      }

      console.log(res)

      const applyList = res.data.list
      this.applyList = this.dataConvertor(applyList)
      const pageInfo = res.data.pageInfo
      this.pageInfo = pageInfo
      // console.log(pageInfo);
    },
    dataConvertor (dataList) {
      const _dataList = dataList
      if (!dataList.length || dataList.length === 0) {
        return _dataList
      }
      _dataList.forEach(items => {
        const memStatus = items.memStatus
        let statusText = ''
        if (memStatus === 0) {
          statusText = '신청'
        } else if (memStatus === 1) {
          statusText = '승인'
        } else if (memStatus === 2) {
          statusText = '대기'
        } else if (memStatus === -1) {
          statusText = '거절'
        }
        if (items.redDt) {
          items.redDt = replaceDateT(items.redDt)
        }

        for (const item in items) {
          if (items[item] === '') {
            items[item] = '-'
          }
          switch (item) {
            case 'memStatus':
            case 'memPhone':
            case 'bankacc':
            case 'memId':
              break
            default:
              if (!isNaN(Number(items[item]))) {
                items[item] = numberWithCommas(items[item])
              }
              break
          }
        }
        // if (item !== "memStatus" || item !== "memPhone" || item !== "bankAcc") {
        //   if (!isNaN(Number(items[item]))) {
        //     items[item] = numberWithCommas(items[item]);
        //   }
        // }
      })
      return _dataList
    },
    async changeListType (evt) {
      const target = evt.target
      let chooseType = target.value
      this.currentStatus = chooseType
      if (chooseType === '') {
        chooseType = 'all'
      }
      this.$router.replace({ params: {}, query: { status: chooseType } })
      // await this.setTableData(1);
    },
    async setMemStatus (evt) {
      const target = evt.target
      const targetStatusValue = target.value
      const targetMemId = target.dataset.memId
      const reqData = {
        memId: targetMemId,
        memStatus: targetStatusValue
      }
      if (targetStatusValue === '-1') {
        reqData.memo = prompt('거절 사유를 입력해주세요.')
      }
      // console.log(reqData);
      try {
        const res = await memStatusChange(reqData)
        if (res.resultCode !== '0') {
          console.log(res.resultCode)
          alert('회원상태 변경에 실패했습니다. 다시 시도해주세요.')
        }
        this.setTableData()
      } catch (e) {
        console.log(e)
        alert('회원상태 변경에 실패했습니다. 다시 시도해주세요.')
      }
    }
  },
  async created () {
    this.currentStatus = this.$route.query.status || this.status
    if (this.currentStatus === 'all') {
      this.currentStatus = ''
    }
    this.setStartDate()
    this.setEndDate()
    this.siteInfo = this.getSiteInfo()
    await this.setTableData(1)
  }
}
</script>

<style scoped>
.searchbar-content > .title {margin-left: 5px;}
</style>
